import Vue from "vue";
import VueRouter from "vue-router";
import { Trans } from "@/plugins/Translation";
import store from "../store";

function load(component) {
  return () => import(`@/views/${component}.vue`);
}

Vue.use(VueRouter);

const routes = [
  {
    path: "/:locale",
    component: {
      template: "<router-view></router-view>"
    },
    beforeEnter: Trans.routeMiddleware,
    children: [
      {
        path: "",
        name: "Home",
        component: load("Home")
      },
      {
        path: "index",
        name: "init",
        component: load("Init")
      },
      {
        path: "iframe",
        name: "iframe",
        component: load("Iframe")
      },
      {
        path: "mercurio",
        name: "mercurio",
        component: load("Mercurio")
      },
      {
        path: "transcription",
        name: "transcription",
        component: load("Transcription")
      },
      {
        path: "fileTranscription",
        name: "fileTranscription",
        component: load("FileTranscription")
      },
      {
        path: "synthesizer",
        name: "synthesizer",
        component: load("Synthesis")
      },
      {
        path: "teatroreal",
        name: "TeatroReal",
        component: load("TeatroReal")
      },
      {
        path: "srt2-1",
        name: "srt2-1",
        component: load("SRT2-1")
      },
      {
        path: "statssrt",
        name: "statssrt",
        component: load("StatsSRT")
      },
      {
        path: "vtttosrt",
        name: "vtttosrt",
        component: load("VttToSRT")
      },
      {
        path: "writer",
        name: "writer",
        component: load("Writer")
      },
      {
        path: "users",
        name: "users",
        component: load("Users")
      },
      {
        path: "applications",
        name: "applications",
        component: load("Applications")
      },
      {
        path: "personalData",
        name: "personalData",
        component: load("PersonalData")
      },
      {
        path: "subtitles",
        name: "subtitles",
        component: load("Subtitles")
      },
      {
        path: "sync",
        name: "sync",
        component: load("Sync")
      },
      {
        path: "versions",
        name: "versions",
        component: load("Versions")
      }
    ]
  },
  {
    path: "*",
    redirect() {
      return Trans.defaultLocale;
    },
    name: "catchAll"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  store.dispatch("fetchAccessToken");
  if (to.name !== "Home") {
    if (!store.state.accessToken) {
      next({ name: "Home", params: { locale: to.params.locale } });
    }
  }
  next();
});

export default router;
